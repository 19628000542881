import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Container, Row } from 'react-bootstrap';
import Dropzone from './Dropzone';
import { useDispatch, useSelector } from 'react-redux';
import {
  uploadFloorPlan,
  uploadBgBuilding,
  loadFile,
  generateSvgs,
} from 'shared/utils/Explorer3dApi';
import { updateExplorer3dSettings, createToast } from 'redux/actions';
import { floorRegex, isBg, isFloorImage } from './utils';
import { FLOOR_STATUS, MEDIA_URL } from './types';
import FloorItem from './FloorItem';
import { kebabCase } from 'lodash';

function FloorTab({ isFullScreen }) {
  const dispatch = useDispatch();

  const [activeId, setActiveId] = useState('');
  const [progress, setProgress] = useState([]);

  const progressRef = useRef(progress);
  const bgRef = useRef(null);
  const mainFrameRef = useRef(null);

  const explorer3d = useSelector(state => state.explorer3d.current);
  const settings = useSelector(state => state.explorer3d.current.settings);
  const maps = settings.floorPlans;

  const active = Object.values(maps).filter(v => v);

  const isAllFilesAttached = Object.values(maps).every(v => v);

  if (isAllFilesAttached && !activeId) {
    setActiveId('1');
  }

  const selectFilesHandler = useCallback(
    async files => {
      const floors = files
        .filter(file => isFloorImage(file.name))
        .map(file => {
          file.number = +floorRegex.exec(file.name)[1];

          return file;
        });
      const bgs = files.filter(file => isBg(file.name));

      for (let i = 0; i < floors.length; i++) {
        const floor = floors[i];

        try {
          const res = await uploadFloorPlan({
            number: floor.number,
            explorer3dId: explorer3d.id,
            file: floor,
            onProgress: p => {
              const progressItem = progressRef.current.find(
                item => item.id === floor.number
              );
              if (progressItem) {
                progressItem.value = p;
                setProgress([...progressRef.current]);
              } else {
                setProgress([
                  ...progressRef.current,
                  { id: floor.number, value: p },
                ]);
              }
            },
          });

          setProgress(progressRef.current.filter(p => p.id !== floor.number));
          dispatch(updateExplorer3dSettings({ floorPlans: res.floorPlans }));
        } catch (error) {
          console.error(error);
        }
      }

      for (const bg of bgs) {
        try {
          const { mapBackgroundPath } = await uploadBgBuilding({
            explorer3dId: explorer3d.id,
            file: bg,
          });

          dispatch(updateExplorer3dSettings({ mapBackgroundPath }));
        } catch (error) {
          console.error(error);
        }
      }
    },
    [dispatch, explorer3d.id]
  );

  const selectFileHandler = useCallback(
    async files => {
      if (files.length < 1) {
        return;
      }

      const number = activeId;

      try {
        const res = await uploadFloorPlan({
          explorer3dId: explorer3d.id,
          number,
          file: files[0],
          onProgress: p => {
            const progressItem = progressRef.current.find(
              item => item.id === number
            );
            if (progressItem) {
              progressItem.value = p;
              setProgress([...progressRef.current]);
            } else {
              setProgress([...progressRef.current, { id: number, value: p }]);
            }
          },
        });

        setProgress(progressRef.current.filter(p => p.id !== number));
        dispatch(updateExplorer3dSettings({ floorPlans: res.floorPlans }));
      } catch (error) {
        dispatch(
          createToast(
            'ERROR',
            error.response.data.message || 'An error occurred'
          )
        );
        console.error(error);
      }
    },
    [activeId, dispatch, explorer3d.id]
  );

  function getRightSidebarRender() {
    if (!activeId)
      return (
        <div className="upload-text">
          ← Files not attached <br />
          Upload files to the panel
        </div>
      );
    else if (activeId && !maps[activeId])
      return (
        <div className="upload-container">
          <Dropzone
            primaryText={`Floor ${activeId}`}
            onFilesSelected={selectFileHandler}
            secondaryText={'Drag and drop file here'}
            buttonText={'Browse file'}
            style={{ width: 400 }}
          />
        </div>
      );
    else
      return (
        <div className="svg-container">
          <img
            crossOrigin="anonymous"
            src={MEDIA_URL + (settings.mapBackgroundPath ?? '')}
            className="bg"
            ref={bgRef}
          />

          <img
            crossOrigin="anonymous"
            src={settings.floorPlans[activeId] ?? ''}
            className="plan"
          />
        </div>
      );
  }

  async function downloadSvgHandler() {
    for (const f in explorer3d.settings.floorPlans) {
      await loadFile(
        explorer3d.settings.floorPlans[f],
        `${kebabCase(explorer3d.name)}_${f}.svg`,
        true
      );
    }
  }

  async function generateSvgHandler() {
    try {
      const settings = await generateSvgs({ id: explorer3d.id });

      dispatch(updateExplorer3dSettings(settings));

      dispatch(createToast('SUCCESS', 'Success'));
    } catch (error) {
      console.log(error);
      dispatch(createToast('ERROR', 'An error occurred'));
    }
  }

  // if (!settings.engrainEmbedLink) return 'attach engrain id';

  return (
    <Container className="d-flex flex-row floor-tab" fluid={isFullScreen}>
      <div className="d-flex flex-column sidebar">
        <div className="header">
          <p>SITEMAP</p>
        </div>

        <div className="p-2">
          <Row className="d-flex flex-row align-items-center justify-content-center pb-2">
            <Button variant="primary" onClick={downloadSvgHandler}>
              Download svgs
            </Button>
          </Row>

          {explorer3d.settings.source === 'engrain' && (
            <Row className="d-flex flex-row align-items-center justify-content-center pb-2">
              <Button variant="primary" onClick={generateSvgHandler}>
                Generate svgs
              </Button>
            </Row>
          )}

          <Dropzone
            onFilesSelected={selectFilesHandler}
            primaryText={
              <>
                Drop files here
                <br /> for bulk upload
              </>
            }
            secondaryText={
              <>For bulk loading use this naming mask: floor001.svg</>
            }
            buttonText={'Browse files'}
          />
        </div>
        <h4 className="floor-text">
          {isAllFilesAttached ? (
            'All files attached'
          ) : (
            <>
              {Object.keys(settings.floorPlans).length - active.length} files of{' '}
              {Object.keys(settings.floorPlans).length} not attached
            </>
          )}
        </h4>
        <div className="floors-container">
          {Object.entries(maps).map(([floor, path]) => {
            const status = path ? FLOOR_STATUS.DOWNLOADED : FLOOR_STATUS.EMPTY;
            const p = progress.find(p => p.id === floor);

            function clickHandler() {
              setActiveId(floor);
            }

            return (
              <FloorItem
                key={floor}
                isSelected={activeId === floor}
                number={floor}
                status={status}
                progress={p && p.value}
                onClick={clickHandler}
                setActiveItem={setActiveId}
              />
            );
          })}
        </div>
      </div>
      <div className="right-sidebar" ref={mainFrameRef}>
        {getRightSidebarRender()}
      </div>
    </Container>
  );
}

export default FloorTab;
