import React, { memo, useEffect, useRef } from 'react';
import { Popover } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const mediaUrl = process.env.REACT_APP_EXPLORER3D_MEDIA_URL;
const CONTAINER_PADDING = 35;
const CONTAINER_PADDING_EXPANDED = 220;
const IMAGE_HEIGHT = 200;
const IMAGE_WIDTH = 200;
const IMAGE_HEIGHT_EXPANDED = 500;
const IMAGE_WIDTH_EXPANDED = 700;

export const SVGRenderer = memo(function SVGRenderer({
  svgContent,
  coords,
  cellValue,
  floorNumber,
}) {
  const svgContainerRef = useRef(null);
  const imageContainerRef = useRef(null);
  const mapBackgroundPath = useSelector(
    state => state.explorer3d.current?.settings?.mapBackgroundPath
  );
  const bgUrl = mediaUrl + (mapBackgroundPath ?? '');
  const isPopupExpanded = useSelector(state => state.editorCSV.isPopupExpanded);

  useEffect(() => {
    const svgContainer = svgContainerRef.current;
    const imageContainer = imageContainerRef.current;
    console.log(svgContent);
    if (svgContent) {
      const elementsWithExternalId =
        svgContent?.querySelectorAll('[external-id]');

      const elementsArray = [...elementsWithExternalId];

      elementsArray.forEach(element => {
        const currExternalId = element.getAttribute('external-id');
        element.classList.remove('with-popup-property');

        if (currExternalId === cellValue) {
          element.classList.remove('with-popup-property-secondary');
          element.classList.add('with-popup-property');
        } else {
          element.classList.add('with-popup-property-secondary');
        }
      });

      if (imageContainer) {
        let style = `
        width: ${IMAGE_WIDTH}px;
        height: ${IMAGE_HEIGHT}px;
        `;

        if (isPopupExpanded) {
          style = `
          width: ${IMAGE_WIDTH_EXPANDED}px;
          height: ${IMAGE_HEIGHT_EXPANDED}px;
          `;
        }

        imageContainer.setAttribute('style', style);
      }

      const containerPadding = isPopupExpanded
        ? CONTAINER_PADDING_EXPANDED
        : CONTAINER_PADDING;

      if (svgContent && svgContainer && imageContainer) {
        const imageContainerWidth = isPopupExpanded
          ? IMAGE_WIDTH_EXPANDED
          : IMAGE_WIDTH;
        const imageContainerHeight = isPopupExpanded
          ? IMAGE_HEIGHT_EXPANDED
          : IMAGE_HEIGHT;
        const scaleFactorWidth =
          (imageContainerWidth - containerPadding * 2) / coords.width;
        const scaleFactorHeight =
          (imageContainerHeight - containerPadding * 2) / coords.height;
        const scaleFactor = Math.min(scaleFactorWidth, scaleFactorHeight);

        const viewBox = svgContent.getAttribute('viewBox')?.split(' ');
        const widthSVG = viewBox?.[2] * scaleFactor;
        const heightSVG = viewBox?.[3] * scaleFactor;
        const top =
          -(coords?.y ?? 0) * scaleFactor +
          (imageContainerHeight - coords.height * scaleFactor) / 2;
        const left =
          -(coords?.x ?? 0) * scaleFactor +
          (imageContainerWidth - coords.width * scaleFactor) / 2;

        const style = `
        position: absolute;
        top: ${top}px;
        left: ${left}px;
        width: ${widthSVG}px;
        height: ${heightSVG}px;
      `;

        svgContent.classList.add('with-popup-image-svg');
        svgContainer.appendChild(svgContent);
        svgContainer.setAttribute('style', style);
      }
    }
  }, [
    cellValue,
    coords,
    coords?.height,
    coords?.width,
    coords?.x,
    coords?.y,
    isPopupExpanded,
    svgContent,
  ]);

  return (
    <Popover className="with-popup-popover" placement="left">
      <Popover.Content>
        <div className="d-flex flex-column align-items-center justify-content-center text-center">
          <p className="with-popup-title">Floor Number: {floorNumber}</p>
          <p className="with-popup-title">External ID: {cellValue}</p>
          {coords && svgContent ? (
            <div className="with-popup-image" ref={imageContainerRef}>
              <div ref={svgContainerRef}>
                <img src={bgUrl} />
              </div>
            </div>
          ) : (
            <div className="with-popup-subtitle">No image</div>
          )}
        </div>
      </Popover.Content>
    </Popover>
  );
});
