import React from 'react';
import { Card, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

function Explorer3dItem({ data }) {
  function clickHandler() {}

  return (
    <Col key={data.id} xs={12} md={4} sm={4} xl={3} lg={3} className="mb-4">
      <Link to={`/widgets/${data.locationId}`}>
        <Card onClick={clickHandler}>
          {data.image && <Card.Img></Card.Img>}
          {!data.image && <div className="explorer3d-preview"></div>}
          <Card.Header>{data.name}</Card.Header>
        </Card>
      </Link>
    </Col>
  );
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Explorer3dItem);
