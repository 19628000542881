import moment from 'moment';
import {
  CELL_TYPE_ENUM,
  DATA_PROP_ENUM,
  FLOOR_PLAN_PROP_NAME_ENUM,
  FLOOR_PLAN_PROP_TYPES,
  UNITS_PROP_NAME_ENUM,
  UNITS_PROP_TYPES,
} from './consts';

export const toBoolean = value => {
  if (typeof value === 'boolean') {
    return value;
  }
  if (typeof value === 'string') {
    switch (value.toUpperCase()) {
      case 'TRUE':
        return true;
      case 'FALSE':
        return false;
      default:
        return null;
    }
  }

  return value;
};

const allTypes = {
  [CELL_TYPE_ENUM.NUMBER]: [
    ...UNITS_PROP_TYPES[CELL_TYPE_ENUM.NUMBER],
    ...FLOOR_PLAN_PROP_TYPES[CELL_TYPE_ENUM.NUMBER],
  ],
  [CELL_TYPE_ENUM.TEXT]: [
    ...UNITS_PROP_TYPES[CELL_TYPE_ENUM.TEXT],
    ...FLOOR_PLAN_PROP_TYPES[CELL_TYPE_ENUM.TEXT],
  ],
  [CELL_TYPE_ENUM.CHECKBOX]: [
    ...UNITS_PROP_TYPES[CELL_TYPE_ENUM.CHECKBOX],
    ...FLOOR_PLAN_PROP_TYPES[CELL_TYPE_ENUM.CHECKBOX],
  ],
  [CELL_TYPE_ENUM.DATE]: [
    ...UNITS_PROP_TYPES[CELL_TYPE_ENUM.DATE],
    ...FLOOR_PLAN_PROP_TYPES[CELL_TYPE_ENUM.DATE],
  ],
};

export const normalizeDataCSV = dataCSV => {
  if (Object.keys(dataCSV).length > 0) {
    Object.values(DATA_PROP_ENUM).forEach(dataPropName => {
      const rows = dataCSV[dataPropName];

      rows.forEach(row => {
        Object.entries(row).forEach(([cellName, cellValue]) => {
          if (
            dataPropName === DATA_PROP_ENUM.Units &&
            cellName === UNITS_PROP_NAME_ENUM.FloorPlanName &&
            cellValue?.length > 0
          ) {
            const referenceRow = dataCSV.FloorPlanTypes?.find(
              row => row[FLOOR_PLAN_PROP_NAME_ENUM.FloorPlanId] === cellValue
            );

            row[cellName] =
              referenceRow[FLOOR_PLAN_PROP_NAME_ENUM.FloorPlanName];
          }

          Object.keys(allTypes).some(type => {
            if (allTypes[type].includes(cellName)) {
              switch (type) {
                case CELL_TYPE_ENUM.NUMBER: {
                  if (typeof cellValue === 'string' && cellValue.length > 0) {
                    row[cellName] = +cellValue;
                  }

                  return true;
                }

                case CELL_TYPE_ENUM.CHECKBOX: {
                  row[cellName] = toBoolean(cellValue);

                  return true;
                }

                case CELL_TYPE_ENUM.TEXT: {
                  return true;
                }

                case CELL_TYPE_ENUM.DATE: {
                  if (typeof cellValue === 'string' && cellValue.length > 0) {
                    row[cellName] = moment(cellValue, 'MM/DD/YY').toDate();
                  }

                  return true;
                }

                default:
                  return true;
              }
            }

            return false;
          });
        });
      });
    });

    return dataCSV;
  }

  return {};
};

// UNITS

export const UNITS_ROW_CELLS_TEMPLATE = Object.values(UNITS_PROP_NAME_ENUM).map(
  name => {
    let rowCell = { type: CELL_TYPE_ENUM.TEXT, name };

    Object.keys(UNITS_PROP_TYPES).some(type => {
      if (UNITS_PROP_TYPES[type].includes(name)) {
        rowCell = { type, name };

        return true;
      }

      return false;
    });

    return rowCell;
  }
);

export const UNITS_HEADER_ROW = {
  rowId: 'header',
  height: 45,
  cells: Object.values(UNITS_PROP_NAME_ENUM).map(name => ({
    type: CELL_TYPE_ENUM.HEADER,
    text: name,
  })),
};

// FLOOR_PLAN_TYPES

export const FLOOR_PLAN_ROW_CELLS_TEMPLATE = Object.values(
  FLOOR_PLAN_PROP_NAME_ENUM
).map(name => {
  let rowCell = { type: CELL_TYPE_ENUM.TEXT, name };

  Object.keys(FLOOR_PLAN_PROP_TYPES).some(type => {
    if (FLOOR_PLAN_PROP_TYPES[type].includes(name)) {
      rowCell = { type, name };

      return true;
    }

    return false;
  });

  return rowCell;
});

export const FLOOR_PLAN_HEADER_ROW = {
  rowId: 'header',
  height: 45,
  cells: Object.values(FLOOR_PLAN_PROP_NAME_ENUM).map(name => ({
    type: CELL_TYPE_ENUM.HEADER,
    text: name,
  })),
};

const UNITS_COLUMNS_TEMPLATE = Object.values(UNITS_PROP_NAME_ENUM).map(name => {
  const column = {
    columnId: name,
    width: 135,
    resizable: true,
  };

  switch (name) {
    case UNITS_PROP_NAME_ENUM.PropertyName:
      column.width = 160;
      break;

    case UNITS_PROP_NAME_ENUM.FloorPlanName:
      column.width = 170;
      break;

    case UNITS_PROP_NAME_ENUM.FloorNumber:
      column.width = 155;
      break;

    case UNITS_PROP_NAME_ENUM.ExternalId:
      column.width = 150;
      break;

    case UNITS_PROP_NAME_ENUM.ExternalUnitNumber:
      column.width = 215;
      break;

    case UNITS_PROP_NAME_ENUM.InternalUnitId:
      column.width = 170;
      break;

    case UNITS_PROP_NAME_ENUM.DisplayName:
      column.width = 230;
      break;

    case UNITS_PROP_NAME_ENUM.UnitDescription:
      column.width = 175;
      break;

    case UNITS_PROP_NAME_ENUM.Availability:
    case UNITS_PROP_NAME_ENUM.AvailableAt:
      column.width = 140;
      break;

    case UNITS_PROP_NAME_ENUM.Price:
      column.width = 100;
      break;

    case UNITS_PROP_NAME_ENUM.Square:
      column.width = 110;
      break;

    case UNITS_PROP_NAME_ENUM.UnitIdDb:
      column.width = 370;
      break;

    default:
      break;
  }

  return column;
});

const FLOOR_PLAN_COLUMNS_TEMPLATE = Object.values(
  FLOOR_PLAN_PROP_NAME_ENUM
).map(name => {
  const column = {
    columnId: name,
    width: 135,
    resizable: true,
  };

  switch (name) {
    case FLOOR_PLAN_PROP_NAME_ENUM.FloorPlanName:
      column.width = 170;
      break;

    case FLOOR_PLAN_PROP_NAME_ENUM.FloorPlanId:
      column.width = 370;
      break;

    case FLOOR_PLAN_PROP_NAME_ENUM.BathroomCount:
      column.width = 175;
      break;

    case FLOOR_PLAN_PROP_NAME_ENUM.BedroomCount:
      column.width = 165;
      break;

    case FLOOR_PLAN_PROP_NAME_ENUM.SpinnerLinks:
      column.width = 170;
      break;

    case FLOOR_PLAN_PROP_NAME_ENUM.VirtualTourLink:
      column.width = 170;
      break;

    default:
      break;
  }

  return column;
});

export const CURRENT_COLUMNS = {
  [DATA_PROP_ENUM.Units]: UNITS_COLUMNS_TEMPLATE,
  [DATA_PROP_ENUM.FloorPlanTypes]: FLOOR_PLAN_COLUMNS_TEMPLATE,
};
