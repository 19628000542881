import React, { useCallback, useEffect, useState } from 'react';
import IslandContainer from './IslandContainer';
import { Row, Button, Col } from 'react-bootstrap';
import FileInput from './FileInput';
import ControlledSelect from './ControlledSelect';
import {
  loadCsv,
  loadFile,
  uploadCsv,
  populateDatabase,
  populateDatabaseManual,
} from 'shared/utils/Explorer3dApi';
import { useDispatch, useSelector } from 'react-redux';
import { Field, getFormValues } from 'redux-form';
import renderField from '../../shared/components/form/Field';
import {
  createToast,
  updateExplorer3dSettings,
  loadDataCSV,
} from 'redux/actions';
import EditorCSV from './EditorCSV/EditorCSV';

const defaultOptions = [
  { name: 'Engrain', value: 'engrain' },
  { name: 'Manual', value: 'manual' },
];

function Database() {
  const explorer3d = useSelector(state => state.explorer3d.current);
  const mapsToRecognize = useSelector(
    state => state.explorer3d.current?.settings?.mapsToRecognize
  );
  const initialSourceType = useSelector(
    state => state.explorer3d.current?.settings?.sourceType
  );
  const explorer3dId = useSelector(state => state.explorer3d.current?.id);
  const [sourceType, setSourceType] = useState(defaultOptions[0].value);
  const dispatch = useDispatch();
  const [isModalShown, setIsModalShown] = useState(false);

  const formValues = useSelector(state =>
    getFormValues('explorer3dForm')(state)
  );

  useEffect(() => {
    if (initialSourceType) {
      setSourceType(initialSourceType);
    }
  }, [initialSourceType]);

  async function uploadFileHandler(files) {
    try {
      const file = files[0];
      await uploadCsv({ file, explorer3dId });
      dispatch(createToast('SUCCESS', 'File uploaded'));
    } catch (error) {
      dispatch(createToast('ERROR', 'Unknown error'));
    }
  }

  async function loadCsvHandler() {
    try {
      const res = await loadCsv({ explorer3dId });
      await loadFile(res, res.split('/')[1]);
    } catch (error) {
      dispatch(createToast('ERROR', 'Unknown error'));
    }
  }

  async function handlePopulate() {
    const settings = await populateDatabase({
      explorer3dId,
      type: sourceType,
      engrainAssetId: +formValues.settings.engrainId,
      engrainEmbedId: formValues.settings.engrainEmbedLink.startsWith('http')
        ? formValues.settings.engrainEmbedLink.split('/').at(-1)
        : formValues.settings.engrainEmbedLink,
    });
    dispatch(updateExplorer3dSettings(settings));
    dispatch(createToast('SUCCESS', 'Database populated'));
  }

  async function handlePopulateManual() {
    const settings = await populateDatabaseManual({
      explorer3dId,
      type: sourceType,
      apartmentsCount: +formValues.apartmentsCount,
    });
    dispatch(updateExplorer3dSettings(settings));
    dispatch(createToast('SUCCESS', 'Database populated'));
  }

  const handleClickOpenEditCSV = useCallback(() => {
    dispatch(loadDataCSV(explorer3dId, mapsToRecognize));
    setIsModalShown(true);
  }, [dispatch, explorer3dId, mapsToRecognize]);

  const handleCloseModal = useCallback(() => {
    setIsModalShown(false);
  }, []);

  return (
    <>
      <IslandContainer>
        <h2 className="explorer3d-item-title">Database</h2>
        {!explorer3d.settings.source && (
          <Row className="mb-2">
            <Col>Source</Col>
            <Col>
              <ControlledSelect
                options={defaultOptions}
                value={sourceType}
                onChange={setSourceType}
              />
            </Col>
          </Row>
        )}

        {sourceType === defaultOptions[0].value &&
          !explorer3d.settings.source && (
            <>
              <Field
                name="settings['engrainEmbedLink']"
                type="text"
                label="Embed link id"
                component={renderField}
                onUpdateStore={val => {
                  dispatch(
                    updateExplorer3dSettings({
                      ...explorer3d.settings,
                      engrainEmbedLink: val,
                    })
                  );
                }}
              />
            </>
          )}
        {sourceType === defaultOptions[1].value &&
          !explorer3d.settings.source && (
            <>
              <Field
                name="apartmentsCount"
                type="text"
                label="Type apartments quantity"
                component={renderField}
                onChange={e => {
                  console.log(e);
                }}
              />
            </>
          )}
        {!explorer3d.settings.source &&
          sourceType === defaultOptions[0].value && (
            <Button onClick={handlePopulate}>Populate database</Button>
          )}
        {!explorer3d.settings.source &&
          sourceType === defaultOptions[1].value && (
            <Button onClick={handlePopulateManual}>Populate database</Button>
          )}
        {explorer3d.settings.source && (
          <>
            <Row className="d-flex align-items-center mb-3">
              <Col className="d-flex">
                <Button variant="primary" onClick={loadCsvHandler}>
                  Download CSV
                </Button>
                <Button
                  variant="primary"
                  className="ml-3"
                  onClick={handleClickOpenEditCSV}
                >
                  Edit CSV
                </Button>
              </Col>
            </Row>

            <Row className="d-flex align-items-center">
              <Col>
                <FileInput
                  buttonLabel="Upload CSV"
                  onSubmit={uploadFileHandler}
                />
              </Col>
            </Row>
          </>
        )}
      </IslandContainer>
      <EditorCSV
        closeEditor={handleCloseModal}
        opened={isModalShown}
        explorer3dId={explorer3dId}
      />
    </>
  );
}

export default Database;
