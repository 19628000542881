import React from 'react';
import { getCellProperty } from '@silevis/reactgrid';
import { FaChevronDown } from 'react-icons/fa';
import {
  DATA_PROP_ENUM,
  FLOOR_PLAN_SORTABLE_PROPS,
  UNITS_PROP_NAME_ENUM,
  UNITS_SORTABLE_PROPS,
} from './consts';

export class CustomHeaderCell {
  constructor(handleCellClick, dataPropName, infoFields) {
    this.handleCellClick = handleCellClick;
    this.dataPropName = dataPropName;
    this.infoFields = infoFields;
  }

  getCompatibleCell(uncertainCell) {
    const text = getCellProperty(uncertainCell, 'text', 'string');
    const value = parseFloat(text);

    return { ...uncertainCell, text, value };
  }

  render(cell) {
    let sortableProps = UNITS_SORTABLE_PROPS;

    if (this.dataPropName === DATA_PROP_ENUM.FloorPlanTypes) {
      sortableProps = FLOOR_PLAN_SORTABLE_PROPS;
    }

    const isSortableColumn = sortableProps.includes(cell.text);

    const handleClick = () => {
      if (isSortableColumn) {
        this.handleCellClick(cell.text);
      }
    };

    let text = cell.text;

    switch (text) {
      case UNITS_PROP_NAME_ENUM.ExternalUnitNumber:
        text = 'Unit number';
        break;

      case UNITS_PROP_NAME_ENUM.CustomField1:
        if (this.infoFields.customField1) {
          text = this.infoFields.customField1;
        }
        break;

      case UNITS_PROP_NAME_ENUM.CustomField2:
        if (this.infoFields.customField2) {
          text = this.infoFields.customField2;
        }
        break;

      case UNITS_PROP_NAME_ENUM.CustomField3:
        if (this.infoFields.customField3) {
          text = this.infoFields.customField3;
        }
        break;

      case UNITS_PROP_NAME_ENUM.CustomField4:
        if (this.infoFields.customField4) {
          text = this.infoFields.customField4;
        }
        break;

      default:
        break;
    }

    return (
      <div
        className={cell.className}
        onCopy={e => e.stopPropagation()}
        onCut={e => e.stopPropagation()}
        onPaste={e => e.stopPropagation()}
        onPointerDown={e => e.stopPropagation()}
        onKeyDown={e => e.stopPropagation()}
        onClick={handleClick}
        style={isSortableColumn ? { cursor: 'pointer' } : {}}
      >
        <span>{text}</span>
        {isSortableColumn && (
          <span className="header-cell-icon">
            <FaChevronDown />
          </span>
        )}
      </div>
    );
  }
}
