import { isNil } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export const amenity = {
  id: uuidv4(),
  name: 'New Amenity',
  tourType: 'text',
  localTourId: null,
  externalTourLink: '',
  icon: '',
};

export const amenityIconsList = [
  'barbell',
  'waves',
  'swimming-pool',
  'garage',
  'charging-station',
  'bank',
  'book-open-text',
  'couch',
  'tree',
  'dog',
  'baby',
  'basketball',
  'scooter',
  'wheelchair',
  'fork-knife',
  'martini',
  'coffee',
  'shirt-folded',
  'dog-paw',
  'outdoor-clubroom',
  'lakeside',
  'burger',
  'bicycle',
  'lobby',
  'gamepad',
  'lamp',
  'mail',
  'laptop',
  'sun',
  'terrace',
  'parking',
  'goggles',
  'balcony',
  'yoga',
  'plant',
  'courtyard',
  'tennis',
  'beauty-salon',
  'medical',
  'laundry',
  'discoball',
  'popcorn',
  'plus-black',
];

export const isNotEmpty = (value = '') => {
  if (typeof value === 'string') {
    return value.trim() !== '';
  } else {
    return !isNil(value);
  }
};

export const viewerUrl = process.env.REACT_APP_WIDGET_PLAYER_URL;
export const mediaUrl = process.env.REACT_APP_EXPLORER3D_MEDIA_URL;
