import React, { useState, useEffect, useRef } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';

import { amenityIconsList, isNotEmpty, viewerUrl } from './utils';
import { uploadAmenity, updateAmenity } from 'shared/utils/Explorer3dApi';
import { useDispatch, useSelector } from 'react-redux';
import { setAmenities } from 'redux/actions';

function AmenityPopup({ ...props }) {
  const { onClose, currentAmenity } = props;
  const fileMaxSize = 1048576;

  const dispatch = useDispatch();

  const nameInputRef = useRef(null);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: 'image/png, image/jpeg, image/jpg, image/webp',
    maxSize: fileMaxSize,
    multiple: false,
    onDrop: (acceptedFiles, fileRejections) => {
      setRejectedFiles(fileRejections);
      setSelectedFile(acceptedFiles[0]);
    },
  });

  const amenitiesBackend = useSelector(
    state => state.explorer3d.current.amenities
  );

  const explorer3d = useSelector(state => state.explorer3d.current);

  const [show, setShow] = useState(true);
  const [amenityName, setAmenityName] = useState(currentAmenity?.name ?? '');
  const [isNameInvalid, setIsNameInvalid] = useState(false);
  const [isIdInvalid, setIsIdInvalid] = useState(false);
  const [isAmenityNameEmpty, setIsAmenityNameEmpty] = useState(false);
  const [isTourIdEmpty, setIsTourIdEmpty] = useState(false);
  const [isTourLinkEmpty, setIsTourLinkEmpty] = useState(false);
  const [isScrolling, setScrolling] = useState(false);
  const [isShowCustomIconSection, setIsShowCustomIconSection] = useState(false);
  const [isFileDragged, setIsFileDragged] = useState(false);

  const [selectedTourType, setSelectedTourType] = useState(
    (currentAmenity?.link?.includes('seenic') || currentAmenity?.link?.includes('theviewvr')) ? 'text' : 'textarea'
  );

  const [selectedLocalTourId, setSelectedLocalTourId] = useState(
    currentAmenity?.link
      ? (new URL(currentAmenity.link)).searchParams.get('locationId')
      : ''
  );
  const [selectedExternalTourLink, setSelectedExternalTourLink] = useState(
    (
      currentAmenity?.link &&
      !currentAmenity.link?.includes('seenic') &&
      !currentAmenity.link?.includes('theviewvr')
    )
      ? currentAmenity.link
      : ''
  );
  const [selectedIcon, setSelectedIcon] = useState(
    currentAmenity?.icon ?? 'barbell'
  );

  const files = acceptedFiles.map(file =>
    Object.assign(file, {
      preview: URL.createObjectURL(file),
    })
  );

  useEffect(() => {
    nameInputRef.current.focus();
  }, []);

  const isFileSelected = files.length > 0;

  const isTextArea = selectedTourType === 'textarea';

  const handleChangeName = e => {
    setAmenityName(e.target.value);
    setIsAmenityNameEmpty(false);
    setIsNameInvalid(e.target.value.length > 21);
  };

  const handleChangeTourId = e => {
    setSelectedLocalTourId(e.target.value);
    setIsTourIdEmpty(false);
    setIsIdInvalid(!/^\d*$/.test(e.target.value));
  };

  const handleChangeTourLink = e => {
    console.log(e.target.value);
    setSelectedExternalTourLink(e.target.value);
    setIsTourLinkEmpty(false);
  };

  const handleChangeIcon = e => {
    setSelectedIcon(e.target.value);
    if (e.target.value === 'plus-black') {
      setIsShowCustomIconSection(true);
    } else {
      setIsShowCustomIconSection(false);
      setSelectedFile(null);
    }
  };

  const handleShow = () => {
    setShow(true);
  };

  const onDragEnter = () => {
    setIsFileDragged(true);
  };

  const onDragLeave = () => {
    setIsFileDragged(false);
  };

  const handleClose = () => {
    setShow(false);
    if (onClose) {
      onClose();
    }
  };

  const handleFormTypeChange = e => {
    setSelectedTourType(e.target.value);
    setSelectedLocalTourId(currentAmenity?.localTourId);
    setSelectedExternalTourLink(currentAmenity?.externalTourLink);
    setIsTourIdEmpty(false);
    setIsTourLinkEmpty(false);
  };

  const hasValidAmenityFields = () =>
    isNotEmpty(amenityName) &&
    (isNotEmpty(selectedLocalTourId) || isNotEmpty(selectedExternalTourLink)) &&
    !isNameInvalid &&
    !isIdInvalid;

  const handleScroll = e => {
    const element = e.target;

    if (element.scrollTop > 0) {
      setScrolling(true);
    } else if (element.scrollTop === 0) {
      setScrolling(false);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();

    setIsAmenityNameEmpty(!isNotEmpty(amenityName));
    setIsTourIdEmpty(!isNotEmpty(selectedLocalTourId));
    setIsTourLinkEmpty(!isNotEmpty(selectedExternalTourLink));

    if (hasValidAmenityFields()) {
      try {
        const icon = selectedFile || selectedIcon;

        const iconData = await fetch(
          `${process.env.PUBLIC_URL}/img/${selectedIcon}.svg`
        );

        const buff = await iconData.blob();
        if (currentAmenity && amenitiesBackend) {
          const res = await updateAmenity({
            id: currentAmenity.id,
            name: amenityName,
            link: selectedLocalTourId
              ? viewerUrl + `?locationId=${selectedLocalTourId}`
              : selectedExternalTourLink,
            index: amenitiesBackend.length,
            explorer3dId: explorer3d.id,
            file: selectedFile || buff,
            fileName: selectedFile ? selectedFile.name : `${icon}.svg`,
          });

          dispatch(setAmenities(res));
          handleClose();

          return;
        }

        const res = await uploadAmenity({
          name: amenityName,
          link: selectedLocalTourId
            ? viewerUrl + `?locationId=${selectedLocalTourId}`
            : selectedExternalTourLink,
          index: amenitiesBackend?.length,
          explorer3dId: explorer3d.id,
          file: selectedFile || buff,
          fileName: selectedFile ? selectedFile.name : `${icon}.svg`,
        });

        dispatch(setAmenities(res));
        handleClose();
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleShow}
        backdrop={false}
        className="amenity-popup"
        scrollable
      >
        <Modal.Header
          className={`${isScrolling ? 'is-scrolling' : ''}`}
          closeButton
          onClick={handleClose}
        >
          <Modal.Title>{currentAmenity?.name ?? 'NEW AMENITY'}</Modal.Title>
        </Modal.Header>
        <Modal.Body onScroll={handleScroll}>
          <Form id="amenity-form" onSubmit={handleSubmit}>
            <Form.Group
              className={`d-flex flex-column align-items-start name-section ${isNameInvalid ? 'expanded' : ''}`}
              controlId="amenity-form.ControlInput1"
            >
              <div className="d-flex flex-column align-items-start name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  value={amenityName}
                  onChange={handleChangeName}
                  placeholder="Amenity name"
                  isInvalid={isNameInvalid || isAmenityNameEmpty}
                  ref={nameInputRef}
                  required
                  autoFocus
                />
                <Form.Control.Feedback type="invalid">
                  {isNameInvalid && 'Name is too long (max. 21 chars)'}
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            <Form.Group
              controlId="formTourType"
              className={`d-flex flex-column align-items-start tour-section ${isTextArea ? 'expanded-textarea' : isIdInvalid ? 'expanded-text' : ''}`}
            >
              <div className="d-flex flex-column align-items-start radio-group">
                <Form.Label className="main-label">Tour</Form.Label>
                <Form.Check
                  className="d-flex flex-row align-items-center"
                  type="radio"
                  id="text-type"
                  label="Local tour"
                  name="form-type"
                  value="text"
                  checked={!isTextArea}
                  onChange={handleFormTypeChange}
                />
                <Form.Check
                  className="d-flex flex-row align-items-center"
                  type="radio"
                  id="textarea-type"
                  label="External tour"
                  name="form-type"
                  value="textarea"
                  checked={isTextArea}
                  onChange={handleFormTypeChange}
                />
              </div>
              <div className="d-flex flex-column align-items-start">
                {isTextArea ? (
                  <>
                    <Form.Label>Iframe Link</Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Enter external tour link here..."
                      rows={2}
                      value={selectedExternalTourLink}
                      onChange={handleChangeTourLink}
                      isInvalid={isTourLinkEmpty}
                      required={isTourIdEmpty}
                      autoFocus
                    />
                  </>
                ) : (
                  <>
                    <Form.Label>ID Location</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="ID Location"
                      value={selectedLocalTourId}
                      onChange={handleChangeTourId}
                      isInvalid={isIdInvalid || isTourIdEmpty}
                      required={isTourLinkEmpty}
                      autoFocus
                    />
                    <Form.Control.Feedback type="invalid">
                      {isIdInvalid && 'ID Location should be a number'}
                    </Form.Control.Feedback>
                  </>
                )}
              </div>
            </Form.Group>
            <Form.Group
              className="d-flex flex-column align-items-start icons-section"
              controlId="amenity-form.ControlInput1"
            >
              <div className="d-flex flex-column align-items-start icons">
                <Form.Label>Icon for amenity</Form.Label>
                <div className="icon-grid">
                  {amenityIconsList.map(icon => (
                    <label
                      key={icon}
                      className={`icon ${selectedIcon === icon ? 'selected' : ''}`}
                    >
                      <input
                        type="radio"
                        id={icon}
                        name="icon"
                        value={icon}
                        onChange={handleChangeIcon}
                        checked={selectedIcon === icon}
                        style={{ display: 'none' }}
                      />
                      <img
                        src={`${process.env.PUBLIC_URL}/img/${icon}.svg`}
                        alt={`Icon ${icon}`}
                      />
                    </label>
                  ))}
                </div>
              </div>
            </Form.Group>
            {isShowCustomIconSection && (
              <Form.Group
                className={`d-flex flex-column align-items-center custom-icon-section`}
              >
                <div
                  className={`bg-white d-flex flex-column align-items-center dnd-section ${isFileDragged ? 'file-dragged' : ''}`}
                  {...getRootProps()}
                  onDragEnter={onDragEnter}
                  onDragLeave={onDragLeave}
                >
                  <Form.Label>Add custom icon</Form.Label>
                  {isFileSelected ? (
                    <div className="d-flex flex-column align-items-center justify-content-center preview-container">
                      <img
                        src={files[0].preview}
                        alt="Preview"
                        className="preview-image"
                      />
                    </div>
                  ) : (
                    <div className="d-flex flex-column align-items-center justify-content-center preview-container text">
                      {rejectedFiles.length > 0 ? (
                        <>
                          <Form.Text className="mt-2 warning">
                            Please provide an image with
                          </Form.Text>
                          <Form.Text className="warning">
                            a correct format (.png, .jpg, .jpeg or .webp)
                          </Form.Text>
                          <Form.Text className="warning">
                            and max size (1Mb)
                          </Form.Text>
                        </>
                      ) : (
                        <>
                          <Form.Text className="mt-2">
                            Drag and drop file here
                          </Form.Text>
                          <Form.Text>(format - .png, .jpg,</Form.Text>
                          <Form.Text>.jpeg or .webp,</Form.Text>
                          <Form.Text>max size - 1Mb)</Form.Text>
                        </>
                      )}
                    </div>
                  )}
                  <Button onClick={open}>Browse file</Button>
                  <input {...getInputProps()} />
                </div>
              </Form.Group>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="submit-button"
            variant="primary"
            onClick={handleSubmit}
            disabled={
              isNameInvalid ||
              isIdInvalid ||
              (rejectedFiles.length > 0 && isShowCustomIconSection)
            }
          >
            {currentAmenity?.name ? 'Save' : 'Add amenity'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AmenityPopup;
